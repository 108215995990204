import { Popup } from './popup'

export interface Response<T = any> {
  data: T
  errors: Error[]
}

export interface Error {
  errorCode: ErrorCode
  message: string
}

export enum ErrorCode {
  UnauthorizedAccess = 4001,
  IncorrectUserOrPassword = 4002,
  CannotPerformActionAsGuest = 4003,
  CannotPerformActionAsUser = 4004,
  CustomerDoesntExist = 4005,
  AmbiguousLogin = 4006,
  PasswordDoesNotMeetLengthRequirements = 4007,
  PhoneNumberAndItsCountryCodeCannotBeEmpty = 4008,
  NewPasswordHasToBeDifferent = 4009,
  IncorrectConfirmToken = 4010,
  IncorrectPin = 4011,
  NoUserAssignedToGivenEMail = 4012,
  NotEnoughParameters = 4013,
  ParkingLotNotFound = 4014,
  InvalidParkerUser = 4016,
  InvalidMonthlyParkingGroup = 4017,
  IncorrectMode = 4019,
  IncorrectParameterException = 4020,
  CarNotFound = 4021,
  CreditCardNotFound = 4022,
  TicketNotFound = 4023,
  IncorrectPassword = 4024,
  ObjectExist = 4025,
  IncorrectValidationCouponCode = 4026,
  ConflictWithCurrentObjectState = 4027,
  MonthlyParkingIsDisabledForThisLocation = 4028,
  ParkCarUsingExitGate = 4029,
  ParkCarWhileAnotherIsParked = 4030,
  ParkingIsClosed = 4031,
  TriedToExitWithUnpaidTicket = 4032,
  PaymentTimeExpired = 4033,
  AreaNoIntegrationEnabled = 4034,
  CurrentPricingIsExpired = 4035,
  HasParkedCars = 4036,
  AutoClearTimeExeeded = 4037,
  ParkingIsUnavailable = 4039,
  TokenExpired = 4040,
  OtherError = 4999,
  NotImplementedException = 5000,
  InconsistentData = 5001,
  FailedSaveCar = 5002,
  FailedSaveCarPayment = 5003,
  FailedParkCar = 5004,
  FailedDeleteCreditCard = 5005,
  FailedBuyMoreTime = 5006,
}

export enum FetchStatus {
  Idle = 'idle',
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}
export interface ErrorData {
  title: string
  description: string
  variant: Popup['variant']
  code: number
}

export type ErrorResponse = Partial<
  Record<Partial<ErrorCode>, Partial<ErrorData>>
>
export type ErrorResponses = Record<ErrorCode, Partial<ErrorData>>
