import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, AppStepper, FlexBox, PageHeading } from 'shared'
import CodeInput from 'shared/CodeInput'
import { resendRegistrationPin } from 'store/customer/thunks'
import { hideLoader, showLoader } from 'store/loader'
import { customerSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'

interface CodeStepProps {
  onSubmit: () => void
  confirmToken: string
}

const CodeStep: React.FunctionComponent<CodeStepProps> = props => {
  const { t } = useTranslation()
  const [codeValid, setCodeValid] = React.useState(false)
  const [pin, setPin] = React.useState('')
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const create = async () => {
    dispatch(showLoader())
    const promise = await dispatch(
      customerSlice.confirmRegister({
        confirmToken: props.confirmToken,
        pin,
      })
    )
    dispatch(hideLoader())
    if (customerSlice.confirmRegister.fulfilled.match(promise)) {
      props.onSubmit()
    } else {
      enqueueSnackbar(promise.payload.description)
    }
  }

  const resendCode = async () => {
    dispatch(showLoader())
    await dispatch(resendRegistrationPin(props.confirmToken))
    dispatch(hideLoader())
  }

  return (
    <>
      <PageHeading
        title={t(Translations.TYPE_ACTIVATION_CODE)}
        subtitle={t(Translations.CODE_MESSAGE)}
        normalizeIcon={false}
      >
        <AppStepper steps={[1, 2, 3]} activeStep={2} />
      </PageHeading>
      <CodeInput
        codeLength={4}
        handleValidation={setCodeValid}
        setPin={setPin}
      />
      <FlexBox hasTopMargin>
        <AppButton
          variant="contained"
          onClick={create}
          disabled={!codeValid || pin.length < 4}
        >
          {t(Translations.CREATE)}
        </AppButton>
        <AppButton variant="outlined" onClick={resendCode}>
          {t(Translations.RESEND_CODE)}
        </AppButton>
      </FlexBox>
    </>
  )
}

export default CodeStep
