import { unwrapResult } from '@reduxjs/toolkit'
import usePaymentCard from 'pages/park_car_credit_card/CreditCardComponents/creditCardHooks/usePaymentCard'
import useFailedPay from 'pages/park_car_summary/ParkCarSummaryHooks/useFailedPay'
import usePay from 'pages/park_car_summary/ParkCarSummaryHooks/usePay'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import paths from 'routing/paths'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import useGetTicket from './useGetTicket'
import useParkCarRedirect from './useParkCarRedirect'
import useParkDetails from './useParkDetails'
import useTimeoutCounter from './useTimoutCounter'

export interface useBuyMoreTimeProps {}

export const useBuyMoreTime = () => {
  const { carId, ticketId } = useParams<{ carId?: string; ticketId?: string }>()
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const [redirectIsLoading, parkCarRedirect] = useParkCarRedirect()
  const { buyMoreTimeStatus } = useSelector(parkCarSlice.promiseStatusSelectors)
  const [getTicketIsLoading] = useGetTicket()
  const { externalPricingId, setIsPaymentRequired, isPaymentRequired } =
    useParkDetails()
  const { totalAmount, pricingPayload } = useParkDetails()
  const { paymentCard } = usePaymentCard()
  const { pay, loading: payIsLoading, onSuccessPayment } = usePay()
  const { handleRejection } = useFailedPay()
  const { runTimer, clearCounter: stopTimer } = useTimeoutCounter()
  const buyMoreTime = async () => {
    const promise = await dispatch(
      parkCarSlice.buyMoreTime({
        carId: carId || ticketId,
        parkTime: !externalPricingId ? pricingPayload : null,
        externalPricingId,
      })
    )
    if (parkCarSlice.buyMoreTime.fulfilled.match(promise)) {
      const res = unwrapResult(promise)
      setIsPaymentRequired(res.requiresPayment)
      if (res.requiresPayment && res.paymentRedirectionAvailable) {
        return parkCarRedirect(carId || ticketId)
      }
      if (res.requiresPayment && !paymentCard) {
        return push(`${paths.parking.addTimeSelectCard}/${carId || ticketId}`)
      }
      onSuccessPayment()
    } else {
      handleRejection(promise.payload)
    }
  }

  const submitBMT = async () => {
    if (isPaymentRequired) {
      await pay()
      stopTimer()
      return setIsPaymentRequired(false)
    }
    if (!isPaymentRequired) {
      await buyMoreTime()
      stopTimer()
      return stopTimer()
    }
  }

  const redirectBMT = async () => {
    runTimer()
    if (!totalAmount) {
      return buyMoreTime()
    }
    if (!paymentCard) {
      return buyMoreTime()
    }
    if (paymentCard) {
      push(`${paths.parking.addTimePay}/${carId || ticketId}`)
    }
  }

  const isLoading = () =>
    redirectIsLoading ||
    buyMoreTimeStatus.pending ||
    getTicketIsLoading ||
    payIsLoading

  return [isLoading(), redirectBMT, submitBMT] as const
}

export default useBuyMoreTime
