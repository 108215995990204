import { Divider } from '@mui/material'
import CreditCardBar from 'pages/my_credit_cards/CreditCardsComponents/CreditCardBar'
import { SelectedLotTitle } from 'pages/park_car/ParkCarComponents'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { AppButton, FlexBox, AppTypography } from 'shared'
import { ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import { CreditCard } from 'typedef/creditCards'
import { Ticket } from 'typedef/ticket'
import { Translations } from 'utils'
import PaymentSuccessful from './ParkCarSummaryComponents/PaymentSuccessful'
import PaymentSummaryDetails from './ParkCarSummaryComponents/PaymentSummaryDetails'
import ZipCodeForm from './ParkCarSummaryComponents/ZipCodeForm'

const StyledCreditCardBar = styled(CreditCardBar)`
  padding-left: 0;
`
export interface PaymentSummaryContainerProps {
  ticket: Ticket
  card?: CreditCard
  paid: boolean
  onSubmit: ({ zipCode }: { zipCode: string }) => void
  pending: boolean
  summaryProps: Pick<Ticket, 'spot' | 'plate'>
}

export const ParkCarSummaryView: React.FC<
  PaymentSummaryContainerProps
> = props => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { t } = useTranslation()

  const onOkClick = React.useCallback(() => {
    dispatch(
      ticketsSlice.actions.setSelectedTicket({
        ticketId: props.ticket.carId,
      })
    )
    push(paths.parking.tickets)
  }, [dispatch, props.ticket.carId, push])

  return (
    <>
      <PaymentSuccessful show={props.paid} />
      {<SelectedLotTitle />}
      {props.card && (
        <>
          <Divider style={{ margin: '10px 0' }} />
          <StyledCreditCardBar card={props.card} noRightAction />
          {!props.paid && (
            <>
              <AppTypography weight="700" color="textSecondary" size={1.25}>
                {t(Translations.BILLING_ZIP_CODE)}
              </AppTypography>
              <AppTypography gutterBottom color="textSecondary">
                {t(Translations.ENTER_ZIP_CODE)}
              </AppTypography>
              <ZipCodeForm
                creditCard={props.card}
                disabled={props.pending}
                onSubmit={props.onSubmit}
              >
                <PaymentSummaryDetails {...props.summaryProps} />
              </ZipCodeForm>
            </>
          )}
        </>
      )}
      {props.paid && (
        <FlexBox>
          <PaymentSummaryDetails {...props.summaryProps} />
          <AppButton variant="contained" color="primary" onClick={onOkClick}>
            {t(Translations.OK)}
          </AppButton>
        </FlexBox>
      )}
    </>
  )
}

export default ParkCarSummaryView
