import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, FlexBox, PageHeading } from 'shared'
import NewPhoneNumberInput from 'shared/PhoneNumberAndCountryCodeInput'
import PhoneNumberContainer from 'shared/sidebar/SignUpContainer'
import {
  countryCodeRegex,
  phoneNumberRegex,
  Translations,
  blurLastInput,
} from 'utils'
import * as Yup from 'yup'

interface SendMeMailProps {
  handleSubmit(phoneCountryCode: number, phoneNumber: string): void
  goBack: () => void
}

const SendMeMail: React.FunctionComponent<SendMeMailProps> = props => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const SignupSchema = Yup.object().shape({
    countryCode: Yup.string().matches(
      countryCodeRegex,
      t(Translations.INVALID_COUNTRY_CODE)
    ),
    phoneNumber: Yup.string()
      .matches(phoneNumberRegex, t(Translations.INVALID_PHONE_NUMBER))
      .required(t(Translations.FIELD_REQUIRED)),
  })

  return (
    <>
      <Formik
        initialValues={{ phoneNumber: '', countryCode: '' }}
        onSubmit={data => {
          props.handleSubmit(parseInt(data.countryCode), data.phoneNumber)
        }}
        validationSchema={SignupSchema}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          validateForm,
          handleSubmit,
        }) => (
          <Form>
            <PageHeading
              title={`${t(Translations.FORGOT_PASSWORD)}?`}
              subtitle={t(Translations.FORGOT_PASSWORD_SEND_ME_MAIL_DESC)}
            />
            <PhoneNumberContainer>
              <NewPhoneNumberInput
                value={`${values.countryCode}${values.phoneNumber}`}
                countryCodeFieldName="countryCode"
                phoneNumberFieldName="phoneNumber"
                errors={errors.phoneNumber}
                onKeyDown={e => {
                  blurLastInput(e)
                }}
              />
            </PhoneNumberContainer>
            <FlexBox hasTopMargin>
              <AppButton
                variant="contained"
                type="button"
                onClick={() => {
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length === 0) {
                      handleSubmit()
                    } else {
                      entries.forEach(el => {
                        enqueueSnackbar(el[1])
                      })
                    }
                  })
                }}
              >
                {t(Translations.SUBMIT)}
              </AppButton>
              <AppButton
                variant="outlined"
                type="button"
                onClick={props.goBack}
              >
                {t(Translations.BACK_TO_LOGIN)}
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SendMeMail
