import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { AppSpinner, PaddingContainer, PageHeading } from 'shared'
import { whiteTheme } from 'themes'
import { useTranslation } from 'react-i18next'
import { Translations } from 'utils'
import { useParams, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { contactInformationsSlice, parkCarSlice } from 'store/slices'
import { useParkCar } from 'pages/park_car/ParkCarHooks'
import { paths } from 'routing'

export const LoginRedirectLoader = () => {
  const { t } = useTranslation()
  const [, , paymentRequired] = useParkCar()
  const { paymentRedirectionAvailable } = useSelector(
    contactInformationsSlice.stateSelectors
  )
  const { carId } = useParams<{ carId: string }>()
  const { lot } = useSelector(parkCarSlice.stateSelectors)

  const externalLogoUrl = lot ? lot.logoLink : undefined
  const title = lot ? lot.lotName : t(Translations.PARKING_MADE_EASY)

  React.useEffect(() => {
    if (carId && paymentRedirectionAvailable !== null) {
      paymentRequired({ carId, paymentRedirectionAvailable })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!carId || paymentRedirectionAvailable === null) {
    return <Redirect to={paths.home.map} />
  }

  return (
    <ThemeProvider theme={whiteTheme}>
      <PaddingContainer>
        <PageHeading
          hasLogo={!lot}
          externalLogoUrl={externalLogoUrl}
          title={title}
        />
        <AppSpinner show relative height="150px" />
      </PaddingContainer>
    </ThemeProvider>
  )
}
