import { showPopup } from 'store/globalPopup/actions'
import { hideLoader, showLoader } from 'store/loader'
import { customerSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { ErrorCode } from 'typedef'

export const useSendEmail = (
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch()

  const sendEmail = async (phoneCountryCode: number, phoneNumber: string) => {
    dispatch(showLoader())
    const promise = await dispatch(
      customerSlice.remindPassword({ phoneCountryCode, phoneNumber })
    )
    dispatch(hideLoader())
    if (customerSlice.remindPassword.fulfilled.match(promise)) {
      setSuccess(true)
    } else {
      if (promise.payload.code === ErrorCode.NoUserAssignedToGivenEMail) {
        setSuccess(true)
        return
      }
      dispatch(
        showPopup({
          ...promise.payload,
          handleAdditionalButtonClick: () =>
            sendEmail(phoneCountryCode, phoneNumber),
        })
      )
    }
  }

  return sendEmail
}
