import React, { useEffect, useState } from 'react'
import { TIME_TO_LOGOUT } from '../../../src/api/config'

interface TimerProps {  
  automaticLogout: () => void
}

const Timer = ({ automaticLogout }: TimerProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(TIME_TO_LOGOUT)

  useEffect(() => {
    const timerId = setInterval(() => {
      setRemainingTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const resetTimer = () => setRemainingTime(TIME_TO_LOGOUT);
        
    window.addEventListener('mousedown', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      window.removeEventListener('mousedown', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    }
  }, []);

  useEffect(() => {
    if(remainingTime == 0){
      automaticLogout();
    }
   
  }, [remainingTime, automaticLogout]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds
    return `${formattedMinutes}:${formattedSeconds}`
  }

  return (
    <div style={{ display: 'none'}} id={'timer'}>{formatTime(remainingTime)}</div>    
  )
}

export default Timer
