import { useParkMode } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { CreditCard } from 'typedef'

export interface useSelectCardProps {}

export const usePaymentCard = () => {
  const dispatch = useAppDispatch()
  const parkMode = useParkMode()
  const [card, setCard] = React.useState<CreditCard | null>(null)
  const parkCarCard = useSelector(parkCarSlice.selectCardId)
  const { addTimeCard, hasCardBeenUsed, zipCode } = useSelector(
    parkCarSlice.stateSelectors
  )

  const setPaymentCard = React.useCallback(
    (card: CreditCard) => {
      parkMode === 'parkCar' &&
        dispatch(parkCarSlice.actions.setCard({ id: card?.creditCardId }))
      parkMode === 'addMoreTime' &&
        dispatch(parkCarSlice.actions.setAddTimeCard({ card }))
    },
    [parkMode, dispatch]
  )

  const unsetPaymentCard = React.useCallback(() => {
    parkMode === 'parkCar' && dispatch(parkCarSlice.actions.setCard({ id: '' }))
    parkMode === 'addMoreTime' &&
      dispatch(parkCarSlice.actions.setAddTimeCard({ card: null }))
  }, [parkMode, dispatch])

  React.useEffect(() => {
    parkMode === 'parkCar' && setCard(parkCarCard)
    parkMode === 'addMoreTime' && setCard(addTimeCard)
  }, [addTimeCard, parkCarCard, parkMode])

  return {
    setPaymentCard,
    unsetPaymentCard,
    paymentCard: card,
    hasCardBeenUsed,
    zipCode,
  }
}

export default usePaymentCard
