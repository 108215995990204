import { useChangeLayout } from 'hooks'
import { useGetTicket, useParkDetails } from 'pages/park_car/ParkCarHooks'
import useBuyMoreTime from 'pages/park_car/ParkCarHooks/useBuyMoreTime'
import { CancelParkCarDialog } from 'pages/park_car_credit_card/CreditCardComponents'
import usePaymentCard from 'pages/park_car_credit_card/CreditCardComponents/creditCardHooks/usePaymentCard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSnackBarProvider, AppSpinner, WhiteBoxContainer } from 'shared'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import ParkCarView from './ParkCarSummaryView'

export const AddTimeSummaryPage: React.FC = props => {
  const { ticketId } = useParams<{ ticketId: string }>()
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = React.useState(false)
  const title = t(Translations.PARK_CAR)
  const { externalPricingId, pricingPayload } = useParkDetails()
  const [loading, , submitBMT] = useBuyMoreTime()
  const { paymentCard } = usePaymentCard()
  const [, , ticket] = useGetTicket()
  const dispatch = useAppDispatch()
  const onClickRight = () => {
    setShowDialog(true)
  }
  useChangeLayout(
    {
      topbar: {
        title,
        hasGoBack: loading ? false : true,
        rightIcon: loading ? null : 'close',
        onClickRight,
      },
      bottomMenu: false,
    },
    'app',
    [loading]
  )
  React.useEffect(
    () => () => {
      dispatch(parkCarSlice.actions.setZipCode(''))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch]
  )

  if (!ticketId || !ticket || (!externalPricingId && !pricingPayload)) {
    return <Redirect to={paths.parking.tickets} />
  }

  return (
    <AppSnackBarProvider>
      <WhiteBoxContainer minHeight={150}>
        <AppSpinner show={loading} />
        {ticket && paymentCard && (
          <ParkCarView
            ticket={ticket}
            card={paymentCard}
            onSubmit={submitBMT}
            paid={false}
            pending={loading}
            summaryProps={ticket}
          />
        )}
      </WhiteBoxContainer>
      <CancelParkCarDialog
        open={showDialog}
        closeDialog={() => setShowDialog(false)}
      />
    </AppSnackBarProvider>
  )
}

export default AddTimeSummaryPage
