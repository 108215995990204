import React, { useEffect } from 'react'
import paths from 'routing/paths'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { AppSpinner, WhiteBoxContainer } from 'shared'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { mapSlice, parkCarSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { ParkCarPayload } from 'typedef'
import ParkCarCard from './ParkCarComponents/ParkCarCard'
import {
  useGetCars,
  useGetPrice,
  useParkCar,
  useParkMode,
} from './ParkCarHooks'
import useBuyMoreTime from './ParkCarHooks/useBuyMoreTime'
import { useTranslation } from 'react-i18next'
import Translations from 'utils/translations'

export interface ParkCarWrapperProps {}

export const ParkCarDefault: React.FC<ParkCarWrapperProps> = props => {
  const { carId } = useParams<{ carId?: string }>()
  const ticket = useSelector((state: AppState) =>
    ticketsSlice.selectById(state, carId)
  )
  const { lot } = useSelector(parkCarSlice.stateSelectors)
  useGetCars()
  const [parkCarLoading, parkCar] = useParkCar()
  const [getPriceLoading, getPrice] = useGetPrice()
  const [isLoadingBMT, buyMoreTime] = useBuyMoreTime()
  const parkMode = useParkMode()
  const { push } = useHistory()
  const dispatch = useAppDispatch()
  const { setPopup, showPopup } = usePopup()
  const { t } = useTranslation()

  const isLoading = React.useCallback(
    () => parkCarLoading || getPriceLoading || isLoadingBMT,

    [parkCarLoading, getPriceLoading, isLoadingBMT]
  )

  const showStoppedLocationOnMap = async () => {
    await dispatch(mapSlice.getLotDetailsMap({ lotId: lot.lotId }))
    dispatch(mapSlice.actions.selectLot({ lotId: lot.lotId, position: true }))
    dispatch(mapSlice.actions.setZoom(17))
    push(paths.home.map)
  }

  useEffect(() => {
    if (lot.isLocationStopped) {
      setPopup({
        title: t(Translations.TEXT2PARK_NOT_AVAILABLE),
        variant: 'error',
        description:
          lot.locationStoppedMessage ??
          t(Translations.TEXT2PARK_IS_CURRENTLY_NOT_AVAILABLE),
        disableBackdropClick: true,
        buttons: [
          {
            onClick: () => {
              showStoppedLocationOnMap()
            },
          },
        ],
      })
      showPopup(true)
    } else if (lot.lotCapacity?.lotIsFull && parkMode !== 'addMoreTime') {
      setPopup({
        title: t(Translations.NO_SPACES_AVAILABLE),
        variant: 'error',
        description:
          lot.lotCapacity?.capacityFullWEBMessage ??
          t(Translations.TEXT2PARK_IS_CURRENTLY_NOT_AVAILABLE),
        disableBackdropClick: true,
        buttons: [
          {
            onClick: () => {
              showStoppedLocationOnMap()
            },
          },
        ],
      })
      showPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = (
    args: Omit<ParkCarPayload, 'parkerMonthlyId' | 'parkerId'>
  ) => {
    if (parkMode === 'parkCar') {
      parkCar(args)
    }
    if (parkMode === 'addMoreTime') {
      buyMoreTime()
    }
  }
  return (
    <WhiteBoxContainer minHeight={150}>
      <AppSpinner show={isLoading()} />
      <ParkCarCard
        initLicencePlate={ticket?.plate}
        initSpotNumber={ticket?.spot}
        lot={lot}
        disabled={isLoading()}
        fetchPricing={getPrice}
        onPayConfirm={onClick}
        addTime={Boolean(ticket)}
      />
    </WhiteBoxContainer>
  )
}
export default ParkCarDefault
