import * as customerApi from 'api/CustomerApi'
import { hasErrorCode } from 'api/utils'
import i18n from 'i18next'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { getErrorPopupProps } from 'utils'
import { PhoneNumberAvailableParams } from '../../typedef/customer'
import Translations from '../../utils/translations'
import { showPopup } from '../globalPopup/actions'
import { hideLoader, showLoader } from '../loader/actions'
import { AppState } from '../store'

//TODO: move to slice
export const resendRegistrationPin = (confirmToken: string) => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<AppState, {}, Action>,
    getState: () => AppState
  ) => {
    dispatch(showLoader())

    const isPinSent = await customerApi.resendRegistrationPin({ confirmToken })
    if (isPinSent) {
      dispatch(
        showPopup({
          variant: 'info',
          title: i18n.t(Translations.RESEND_CODE),
          description: i18n.t(Translations.ACTIVATION_CODE_HAS_BEEN_SENT),
          handleAdditionalButtonClick: () => undefined,
        })
      )
    } else {
      dispatch(
        showPopup({
          variant: 'warning',
          title: `${i18n.t(Translations.OOPS)}`,
          description: i18n.t(Translations.ACTIVATION_CODE_SEND_FAIL),
          handleAdditionalButtonClick: () => {
            dispatch(resendRegistrationPin(confirmToken))
          },
        })
      )
    }

    dispatch(hideLoader())
  }
}

interface PhoneNumberAvailableArgs extends PhoneNumberAvailableParams {
  callback: () => void
}
export const phoneNumberAvailable = ({
  callback,
  ...args
}: PhoneNumberAvailableArgs) => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<AppState, {}, Action>,
    getState: () => AppState
  ) => {
    dispatch(showLoader())
    const result = await customerApi.phoneNumberAvailable(args)
    dispatch(hideLoader())

    if (hasErrorCode(result)) {
      dispatch(
        showPopup({
          ...getErrorPopupProps(result),
          handleAdditionalButtonClick: () =>
            dispatch(phoneNumberAvailable({ callback, ...args })),
        })
      )
      return false
    }
    if (!result.is) {
      dispatch(
        showPopup({
          title: i18n.t(Translations.ACCOUNT_ALREADY_EXISTS),
          description: i18n.t(Translations.PHONE_NUMBER_ALREADY_EXISTS),
          variant: 'error',
        })
      )
      return false
    }
    callback()
  }
}
