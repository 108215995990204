import { CreditCard } from './creditCards'
import { CustomerTokens } from './customer'
import { Currency } from './ticket'

export interface Receipt {
  carId: string
  dateInUTC: string
  locationName: string
  areaAddress: string
  cardUsed: boolean
  creditCard?: CreditCard
  amount: Amount
  clientMail?: string
  externalServiceDeliversReceipts: boolean
}

export interface Amount {
  totalAmount: number
  convenienceFee?: number
  currency: Currency
}

export interface GetReceiptsData {
  receipts: Receipt[]
}

export type SendReceiptsPayload = {
  email: string
  carId: string
}
export type SendReceiptsData = boolean

export type SendQuestionPayload = {
  email: string
  message: string
  carId: string
}
export type SendQuestionData = boolean

export type AddContactInformationsPayload = {
  carId: string
  email?: string
  countryCode?: string
  phoneNumber?: string
  confirmToken?: string
  pin?: string
}

export type AddContactInformationsData = {
  newTokens: CustomerTokens
}

export interface SendVerificationSMSPayload {
  phoneNumber: string
  countryCode: number
  tokenValuePart: string
  verificationType: VerificationType
}

export interface SendVerificationSMSData {
  confirmToken: string
  verificationType: VerificationType
}

export interface GetContactInformationsData {
  email: string
  countryCode: number | string
  phoneNumber: string
}

export enum VerificationType {
  SavingGuestsPhoneNumber = 1,
}
