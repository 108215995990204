import React from 'react'
import Timer from './Timer'
import { useAppDispatch } from 'store/store'
import { useSelector } from 'react-redux'
import { customerSlice } from 'store/slices'


export interface AutoLogoutProps {
  children: React.ReactNode
}

const AutoLogout = ({ children }: AutoLogoutProps) => {  
  const dispatch = useAppDispatch()
  const { role } = useSelector(customerSlice.stateSelectors)

  const automaticLogout = () => {
    dispatch(customerSlice.actions.destroySession())
  }

  return (
    <>
      <div>
          {role === 1 && (            
              <Timer automaticLogout={automaticLogout} />          
          )}
      </div>
      {children}
    </>
  )
}

export default AutoLogout
