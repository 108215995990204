import { unwrapResult } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import usePaymentCard from 'pages/park_car_credit_card/CreditCardComponents/creditCardHooks/usePaymentCard'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import {
  parkCarSlice,
  ticketsSlice,
  contactInformationsSlice,
} from 'store/slices'
import { ParkCarState } from 'store/slices/parkCarSlice types'
import { useAppDispatch } from 'store/store'
import { CarStatus, ErrorCode, ParkCarData, ParkCarPayload } from 'typedef'
import useExpiredPricingPopup from '../ParkCarT2/T2Hooks/useExpiredPricingPopup'
import useAutoClearTimeExceedingPopup from './useAutoClearTimeExceedingPopup'
import useGetTicket from './useGetTicket'
import useParkCarRedirect from './useParkCarRedirect'
import useParkDetails from './useParkDetails'

export interface useParkCarProps {}

export const useParkCar = () => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const showExpiredPopup = useExpiredPricingPopup()
  const showAutoClearTimeExceedingPopup = useAutoClearTimeExceedingPopup()
  const { enqueueSnackbar } = useSnackbar()
  const { lot, pricing } = useSelector(parkCarSlice.stateSelectors)
  const { parkCarStatus } = useSelector(parkCarSlice.promiseStatusSelectors)
  const [redirectIsLoading, parkCarRedirect] = useParkCarRedirect()
  const [getTicketIsLoading, getTicket] = useGetTicket()
  const { externalPricingId } = useParkDetails()
  const { unsetPaymentCard } = usePaymentCard()
  const paymentRequired = async (
    args: Pick<ParkCarData, 'carId' | 'paymentRedirectionAvailable'>
  ) => {
    const ticketPromise = await getTicket(args.carId)
    if (ticketsSlice.getTicket.fulfilled.match(ticketPromise)) {
      const res = unwrapResult(ticketPromise)
      if (res.carStatus === CarStatus.Parked) {
        dispatch(
          ticketsSlice.actions.setSelectedTicket({ ticketId: res.carId })
        )
        dispatch(parkCarSlice.actions.resetParkingDetails())
        return push(paths.parking.tickets)
      }
      dispatch(
        parkCarSlice.actions.setPricing({
          ...pricing,
          parkingExpireDateTime: res.parkingExpireDateTime,
        })
      )
      dispatch(
        parkCarSlice.actions.updateParkingDetails<
          Partial<ParkCarState['parkingDetails']>
        >({
          spot: res.spot,
          plate: res.plate,
        })
      )
    }
    if (args.paymentRedirectionAvailable) {
      parkCarRedirect(args.carId)
    } else {
      push(`${paths.parking.selectCard}/${args.carId}`)
    }
  }

  const parkCar = async (
    args: Omit<ParkCarPayload, 'parkerMonthlyId' | 'parkerId'>
  ) => {
    let payload: ParkCarPayload = {
      plate: args.plate,
      spot: args.spot,
      externalPricingId,
      parkerId: lot?.parkerId,
      parkerMonthlyId: lot.monthlyParkerInfo?.parkerMonthlyId,
    }

    if (lot.timeSelectOption) {
      payload = { ...payload, parkTime: args.parkTime }
    }
    const promise = await dispatch(parkCarSlice.parkCar(payload))
    if (parkCarSlice.parkCar.fulfilled.match(promise)) {
      const res = unwrapResult(promise)
      unsetPaymentCard()

      dispatch(
        contactInformationsSlice.actions.setRequirements({
          contactDataRequired: res.contactDataRequired,
          customerHasEmail: res.customerHasEmail,
          paymentRedirectionAvailable: res.paymentRedirectionAvailable,
        })
      )
      if (res.carStatus === CarStatus.RequiresPayment) {
        if (!res.customerHasEmail) {
          push(`${paths.parking.contactInformations}/${res.carId}`)
        } else {
          paymentRequired(res)
        }
      }
      if (res.carStatus === CarStatus.Parked) {
        await getTicket(res.carId)
        dispatch(
          ticketsSlice.actions.setSelectedTicket({ ticketId: res.carId })
        )
        dispatch(parkCarSlice.actions.resetParkingDetails())
        if (!res.customerHasEmail) {
          push(`${paths.parking.contactInformations}/${res.carId}`)
        } else {
          push(paths.parking.tickets)
        }
      }
    } else {
      if (promise.payload.code === ErrorCode.CurrentPricingIsExpired) {
        return showExpiredPopup()
      }
      if (promise.payload.code === ErrorCode.AutoClearTimeExeeded) {
        return showAutoClearTimeExceedingPopup()
      }
      enqueueSnackbar(promise.payload.description, {})
    }
  }

  const isLoading = () =>
    redirectIsLoading || getTicketIsLoading || parkCarStatus.pending

  return [isLoading(), parkCar, paymentRequired] as const
}

export default useParkCar
