import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { lotApiError, ticketsApiError } from 'api/apiErrors'
import * as lotApi from 'api/LotApi'
import * as priceApi from 'api/PriceApi'
import * as ticketsApi from 'api/TicketsApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import {
  BuyMoreTimeData,
  BuyMoreTimeExternalPayload,
  BuyMoreTimePayload,
  CreditCard,
  ErrorData,
  ExternalPricingData,
  FetchStatus,
  GetExternalPricingPayload,
  GetLotDetailsParkData,
  GetLotDetailsParkPayload,
  GetPriceData,
  GetPricePayload,
  ParkCarData,
  ParkCarPayload,
  ParkCarRedirectData,
  ParkCarRedirectPayload,
  ParkCarTransactionStatusData,
  ParkCarTransactionStatusPayload,
} from 'typedef'
import { creditCardsSlice, customerSlice, sliceUtil, mapSlice } from '.'
import { FETCH, initialPromiseStatus, PARK_CAR } from './constants'
import { ParkCarState } from './parkCarSlice types'

/**
 * state
 */
const initialPromiseStatuses: ParkCarState['promisesStatus'] = {
  getLotDetailsPark: initialPromiseStatus,
  getPrice: initialPromiseStatus,
  parkCar: initialPromiseStatus,
  buyMoreTime: initialPromiseStatus,
  parkCarRedirect: initialPromiseStatus,
  parkCarTransactionStatus: initialPromiseStatus,
  getExternalPricing: initialPromiseStatus,
}
const initialState: ParkCarState = {
  lot: null,
  pricingPayload: null,
  pricing: null,
  ticketId: null,
  cardId: null,
  addTimeCard: null,
  hasCardBeenUsed: false,
  zipCode: null,
  promisesStatus: initialPromiseStatuses,
  parkingDetails: {
    fees: [],
    totalAmount: 0,
  },
  isPaymentRequired: false,
  providedPlate: null,
  providedSpotNumber: null,
}

/**
 * thunks
 */
export const getLotDetailsPark = createAsyncThunk<
  GetLotDetailsParkData,
  GetLotDetailsParkPayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}/${FETCH}/getLotDetailsPark`, async (params, thunkApi) => {
  try {
    const resp = await lotApi.getLotDetailsPark(params)
    if (resp.newTokens) {
      thunkApi.dispatch(customerSlice.actions.setLogin(resp.newTokens))
    }
    if (resp.corporationLotsToken) {
      thunkApi.dispatch(
        customerSlice.actions.setCorporationToken(resp.corporationLotsToken)
      )
    }
    thunkApi.dispatch(mapSlice.actions.addLot(resp))
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      lotApiError.getLotDetailsPark
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})
export const getPrice = createAsyncThunk<
  GetPriceData & { time: number },
  GetPricePayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}/${FETCH}/getPrice`, async (params, thunkApi) => {
  try {
    const resp = await priceApi.getPrice(params)
    const time = Date.now()
    return { ...resp, time }
  } catch (error) {
    const errorMessage = getErrorMessage(getErrorCode(error))
    return thunkApi.rejectWithValue(errorMessage)
  }
})
export const getExternalPricing = createAsyncThunk<
  ExternalPricingData,
  GetExternalPricingPayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}/${FETCH}/getExternalPricing`, async (params, thunkApi) => {
  try {
    const resp = await priceApi.getExternalPricing(params)
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(getErrorCode(error))
    return thunkApi.rejectWithValue(errorMessage)
  }
})
export const parkCar = createAsyncThunk<
  ParkCarData,
  ParkCarPayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}/${FETCH}/parkCar`, async (params, thunkApi) => {
  try {
    const resp = await ticketsApi.parkCar(params)
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      ticketsApiError.parkCar
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const buyMoreTime = createAsyncThunk<
  BuyMoreTimeData,
  BuyMoreTimePayload | BuyMoreTimeExternalPayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}${FETCH}/buyMoreTime`, async (params, thunkApi) => {
  try {
    const resp = await ticketsApi.buyMoreTime(params)
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      ticketsApiError.buyMoreTime
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const parkCarRedirect = createAsyncThunk<
  ParkCarRedirectData,
  ParkCarRedirectPayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}/${FETCH}/parkCarRedirect`, async (params, thunkApi) => {
  try {
    const resp = await ticketsApi.parkCarRedirect(params)
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      ticketsApiError.parkCarRedirect
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const parkCarTransactionStatus = createAsyncThunk<
  ParkCarTransactionStatusData,
  ParkCarTransactionStatusPayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}/${FETCH}/parkCarTransactionStatus`, async (params, thunkApi) => {
  try {
    const resp = await ticketsApi.parkCarTransactionStatus(params)
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      ticketsApiError.parkCarTransactionStatus
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const getSelectedTicket = createAsyncThunk<
  ParkCarData,
  ParkCarPayload,
  { rejectValue: ErrorData }
>(`${PARK_CAR}/${FETCH}/getSelectedTicket`, async (params, thunkApi) => {
  try {
    const resp = await ticketsApi.parkCar(params)
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      ticketsApiError.parkCar
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

/**
 * reducers
 */
const parkCarSlice = createSlice({
  name: 'parkCar',
  initialState,
  reducers: {
    setlot(state, { payload }: PayloadAction<GetLotDetailsParkData>) {
      state.lot = payload
    },
    setPricing(state, { payload }: PayloadAction<GetPriceData>) {
      state.pricing = payload
    },
    setTicket(state, { payload }: PayloadAction<{ carId: string }>) {
      state.ticketId = payload.carId
    },
    setCard(state, { payload }: PayloadAction<{ id: string }>) {
      state.cardId = payload.id
    },
    setParkingDetails(
      state,
      { payload }: PayloadAction<Required<ParkCarState['parkingDetails']>>
    ) {
      state.parkingDetails = payload
    },

    updateParkingDetails(
      state,
      { payload }: PayloadAction<Partial<ParkCarState['parkingDetails']>>
    ) {
      state.parkingDetails = {
        ...state.parkingDetails,
        ...payload,
      }
    },
    resetParkingDetails(state) {
      state.parkingDetails = { fees: [], totalAmount: 0 }
    },
    setAddTimeCard(state, { payload }: PayloadAction<{ card: CreditCard }>) {
      state.addTimeCard = payload.card
    },
    setZipCode(state, { payload }: PayloadAction<string | null>) {
      state.zipCode = payload
    },
    setHasCardBeenUsed(state, { payload }: PayloadAction<boolean>) {
      state.hasCardBeenUsed = payload
    },
    setIsPaymentRequired(state, { payload }: PayloadAction<boolean>) {
      state.isPaymentRequired = payload
    },
    setProvidedPlate(
      state,
      { payload }: PayloadAction<{ providedPlate: string }>
    ) {
      state.providedPlate = payload.providedPlate
    },
    setProvidedSpotNumber(
      state,
      { payload }: PayloadAction<{ providedSpotNumber: string }>
    ) {
      state.providedSpotNumber = payload.providedSpotNumber
    },
    resetAddTimeCard(state) {
      state.addTimeCard = null
    },
    resetTicket(state) {
      state.ticketId = null
    },
    resetPricing(state) {
      state.pricing = {
        amount: null,
        convenienceFee: null,
        parkingExpireDateTime: null,
        convenienceFeeName: null,
        totalAmount: null,
        validations: [],
      }
      state.pricingPayload = null
    },
    resetPromiseStatus(
      state,
      { payload }: PayloadAction<keyof ParkCarState['promisesStatus']>
    ) {
      state.promisesStatus[payload] = initialPromiseStatus
    },
    resetState(state) {
      state = initialState
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getLotDetailsPark
       */
      .addCase(getLotDetailsPark.fulfilled, (state, action) => {
        if (
          // eslint-disable-next-line
          state.promisesStatus.getLotDetailsPark.requestId !=
          action.meta.requestId
        )
          return
        parkCarSlice.caseReducers.setlot(state, action)
        state.promisesStatus.getLotDetailsPark = {
          ...sliceUtil.fulfilledPromise(),
          id: action.payload.lotId,
        }
      })
      .addCase(getLotDetailsPark.pending, ({ promisesStatus }, action) => {
        promisesStatus.getLotDetailsPark = {
          ...sliceUtil.pendingPromise(promisesStatus.getLotDetailsPark, action),
          id: action.meta.arg.lotId,
        }
      })
      .addCase(getLotDetailsPark.rejected, (state, action) => {
        if (
          // eslint-disable-next-line
          state.promisesStatus.getLotDetailsPark.requestId !=
          action.meta.requestId
        )
          return
        if (action.meta.aborted) {
          state.promisesStatus.getLotDetailsPark = initialPromiseStatus
        } else {
          state.promisesStatus.getLotDetailsPark = {
            ...sliceUtil.rejectedPromise(action.payload),
            id: action.meta.arg.lotId,
          }
        }
      })

      /**
       * getPrice
       */
      .addCase(getPrice.fulfilled, (state, action) => {
        parkCarSlice.caseReducers.setPricing(state, action)
        state.pricingPayload = { ...action.meta.arg, time: action.payload.time }
        state.promisesStatus.getPrice = sliceUtil.fulfilledPromise()
      })
      .addCase(getPrice.pending, ({ promisesStatus }, action) => {
        promisesStatus.getPrice = sliceUtil.pendingPromise(
          promisesStatus.getPrice,
          action
        )
      })
      .addCase(getPrice.rejected, (state, action) => {
        state.promisesStatus.getPrice = sliceUtil.rejectedPromise(
          action.payload
        )
      })

      /**
       * parkCar
       */
      .addCase(parkCar.fulfilled, (state, action) => {
        state.promisesStatus.parkCar = {
          ...initialPromiseStatus,
          status: [FetchStatus.Fulfilled],
        }
      })
      .addCase(parkCar.pending, ({ promisesStatus }, action) => {
        promisesStatus.parkCar = {
          ...initialPromiseStatus,
          status: promisesStatus.parkCar.status.concat(FetchStatus.Pending),
        }
      })
      .addCase(parkCar.rejected, (state, action) => {
        state.promisesStatus.parkCar = {
          ...action.payload,
          status: [FetchStatus.Rejected],
        }
      })
      /**
       * getExternalPricing
       */
      .addCase(getExternalPricing.fulfilled, (state, action) => {
        state.promisesStatus.getExternalPricing = {
          ...initialPromiseStatus,
          status: [FetchStatus.Fulfilled],
        }
      })
      .addCase(getExternalPricing.pending, ({ promisesStatus }, action) => {
        promisesStatus.getExternalPricing = {
          ...initialPromiseStatus,
          status: promisesStatus.getExternalPricing.status.concat(
            FetchStatus.Pending
          ),
        }
      })
      .addCase(getExternalPricing.rejected, (state, action) => {
        state.promisesStatus.getExternalPricing = {
          ...action.payload,
          status: [FetchStatus.Rejected],
        }
      })

      /**
       * parkCarRedirect
       */
      .addCase(parkCarRedirect.fulfilled, (state, action) => {
        state.promisesStatus.parkCarRedirect = {
          ...initialPromiseStatus,
          status: [FetchStatus.Fulfilled],
        }
      })
      .addCase(parkCarRedirect.pending, ({ promisesStatus }, action) => {
        promisesStatus.parkCarRedirect = {
          ...initialPromiseStatus,
          status: promisesStatus.parkCarRedirect.status.concat(
            FetchStatus.Pending
          ),
        }
      })
      .addCase(parkCarRedirect.rejected, (state, action) => {
        state.promisesStatus.parkCarRedirect = {
          ...action.payload,
          status: [FetchStatus.Rejected],
        }
      })

      /**
       * parkCarRedirect
       */
      .addCase(parkCarTransactionStatus.fulfilled, (state, action) => {
        state.promisesStatus.parkCarTransactionStatus = {
          ...initialPromiseStatus,
          status: [FetchStatus.Fulfilled],
        }
      })
      .addCase(
        parkCarTransactionStatus.pending,
        ({ promisesStatus }, action) => {
          promisesStatus.parkCarTransactionStatus = {
            ...initialPromiseStatus,
            status: promisesStatus.parkCarTransactionStatus.status.concat(
              FetchStatus.Pending
            ),
          }
        }
      )
      .addCase(parkCarTransactionStatus.rejected, (state, action) => {
        state.promisesStatus.parkCarTransactionStatus = {
          ...action.payload,
          status: [FetchStatus.Rejected],
        }
      })

      /**
       * buyMoreTime
       */
      .addCase(buyMoreTime.fulfilled, (state, action) => {
        state.promisesStatus.buyMoreTime = {
          ...initialPromiseStatus,
          status: [FetchStatus.Fulfilled],
        }
      })
      .addCase(buyMoreTime.pending, ({ promisesStatus }, action) => {
        promisesStatus.buyMoreTime = {
          ...initialPromiseStatus,
          status: promisesStatus.buyMoreTime.status.concat(FetchStatus.Pending),
        }
      })
      .addCase(buyMoreTime.rejected, (state, action) => {
        state.promisesStatus.buyMoreTime = {
          ...action.payload,
          status: [FetchStatus.Rejected],
        }
      })
  },
})

export default parkCarSlice.reducer
export const { actions } = parkCarSlice

/**
 * selectors
 */
export const selectPromiseStatus = (state: RootState) =>
  state.parkCar.promisesStatus
export const stateSelectors = (state: RootState) => state.parkCar
export const selectPricing = (state: RootState) => state.parkCar.pricing
export const selectedTicket = (state: RootState) => {
  if (!state.parkCar.ticketId) return null
  return state.tickets.entities[state.parkCar.ticketId]
}

export const selectCardId = (state: RootState) => {
  const id = state.parkCar.cardId
  if (!id) {
    return null
  }
  return creditCardsSlice.selectEntities(state)[id]
}
export const promiseStatusSelectors = (state: RootState) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    getLotDetailsParkStatus: sliceUtil.fetchStatus(promises.getLotDetailsPark),
    getPriceStatus: sliceUtil.fetchStatus(promises.getPrice),
    parkCarStatus: sliceUtil.fetchStatus(promises.parkCar),
    buyMoreTimeStatus: sliceUtil.fetchStatus(promises.buyMoreTime),
    parkCarRedirectStatus: sliceUtil.fetchStatus(promises.parkCarRedirect),
    getExternalPricingStatus: sliceUtil.fetchStatus(
      promises.getExternalPricing
    ),
  }
}
