import { ErrorCode, ErrorResponses } from 'typedef'
import T from 'utils/translations'

// TODO:
export const defaultErrorData: ErrorResponses = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
    code: ErrorCode.OtherError,
  },
  [ErrorCode.UnauthorizedAccess]: {
    title: T.OOPS,
    description: 'Unauthorized',
    variant: 'warning',
  },
  [ErrorCode.IncorrectUserOrPassword]: {
    title: T.OOPS,
    description: T.WRONG_LOGIN_OR_PASSWORD,
    variant: 'warning',
  },
  [ErrorCode.CannotPerformActionAsGuest]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.CannotPerformActionAsUser]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.CustomerDoesntExist]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.AmbiguousLogin]: {
    title: T.OOPS,
    description: T.AMBIGUOUS_LOGIN,
    variant: 'warning',
  },
  [ErrorCode.PasswordDoesNotMeetLengthRequirements]: {
    title: T.OOPS,
    description: T.MINIMUM_8_CHARACTERS_AT_LEAST_ONE_CAPITAL,
    variant: 'warning',
  },
  [ErrorCode.PhoneNumberAndItsCountryCodeCannotBeEmpty]: {
    title: T.OOPS,
    description: T.PHONE_CANNOT_BE_EMPTY,
    variant: 'warning',
  },
  [ErrorCode.NewPasswordHasToBeDifferent]: {
    title: T.INCORRECT_PASSWORD,
    description: T.OLD_PASSWORD_MUST_BE_DIFFERENT_THEN_THE_NEW_PASSWORD,
    variant: 'error',
  },
  [ErrorCode.IncorrectConfirmToken]: {
    title: T.OOPS,
    description: T.INCORRECT_CONFIRM_TOKEN,
    variant: 'warning',
  },
  [ErrorCode.IncorrectPin]: {
    title: T.OOPS,
    description: T.INVALID_ACTIVATION_CODE,
    variant: 'warning',
  },
  [ErrorCode.NoUserAssignedToGivenEMail]: {
    title: T.OOPS,
    description: T.NO_E_MAIL_ASSIGNED_TO_GIVEN_PHONE_NUMBER,
    variant: 'error',
  },
  [ErrorCode.NotEnoughParameters]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.ParkingLotNotFound]: {
    title: T.OOPS,
    description: T.NO_PARKING_FOUND,
    variant: 'warning',
  },
  [ErrorCode.InvalidParkerUser]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.InvalidMonthlyParkingGroup]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.IncorrectMode]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.IncorrectParameterException]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.CarNotFound]: {
    title: T.OOPS,
    description: T.NO_CARS_FOUND,
    variant: 'warning',
  },
  [ErrorCode.CreditCardNotFound]: {
    title: T.OOPS,
    description: T.NO_CREDIT_CARDS_FOUND,
    variant: 'warning',
  },
  [ErrorCode.TicketNotFound]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.IncorrectPassword]: {
    title: T.OOPS,
    description: T.INCORRECT_PASSWORD,
    variant: 'error',
  },
  [ErrorCode.ObjectExist]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.IncorrectValidationCouponCode]: {
    title: T.INVALID_CODE,
    description: T.PROVIDED_CODE_IS_INVALID,
    variant: 'error',
  },
  [ErrorCode.ConflictWithCurrentObjectState]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.MonthlyParkingIsDisabledForThisLocation]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.ParkCarUsingExitGate]: {
    title: T.ERROR,
    description: T.UNFORTUNATELY_WE_CANNOT_FIND_YOUR_TICKET,
    variant: 'error',
  },
  [ErrorCode.ParkCarWhileAnotherIsParked]: {
    title: T.ERROR,
    description: T.IT_SEEMS_THAT_YOUR_VEHICLE_IS_CURRENTLY_ALREADY_PARKED,
    variant: 'error',
  },
  [ErrorCode.ParkingIsClosed]: {
    title: T.OOPS,
    description: T.PARKING_IS_CLOSED,
    variant: 'error',
  },
  [ErrorCode.TriedToExitWithUnpaidTicket]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'error',
  },
  [ErrorCode.PaymentTimeExpired]: {
    title: T.TIMEOUT,
    description: T.YOUR_SESSION_HAS_EXPIRED_PLEASE_START_OVER,
    variant: 'error',
  },
  [ErrorCode.AreaNoIntegrationEnabled]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'error',
  },
  [ErrorCode.AutoClearTimeExeeded]: {
    title: T.OOPS,
    description: T.RATE_HAS_EXPIRED_PLEASE_SELECT_YOUR_TIME_AGAIN,
    variant: 'error',
  },
  [ErrorCode.NotImplementedException]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.InconsistentData]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.FailedSaveCar]: {
    title: T.OOPS,
    description: T.YOUR_CAR_CANNOT_BE_ADDED,
    variant: 'warning',
  },
  [ErrorCode.FailedSaveCarPayment]: {
    title: T.TRANSACTION_DECLINED,
    description: T.YOUR_TRANSACTION_HAS_BEEN_REJECTED,
    variant: 'error',
  },
  [ErrorCode.FailedParkCar]: {
    title: T.OOPS,
    description: T.FAILED_PARK_CAR,
    variant: 'warning',
  },
  [ErrorCode.FailedDeleteCreditCard]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.FailedBuyMoreTime]: {
    title: T.OOPS,
    description: T.FAILED_PARK_CAR,
    variant: 'warning',
  },
  [ErrorCode.CurrentPricingIsExpired]: {
    variant: 'error',
    description: T.RATE_HAS_EXPIRED_PLEASE_SELECT_YOUR_TIME_AGAIN,
    title: T.RATE_HAS_EXPIRED,
  },
  [ErrorCode.HasParkedCars]: {
    variant: 'error',
    description: T.HAS_PARKED_CARS,
    title: T.OOPS,
  },
  [ErrorCode.ParkingIsUnavailable]: {
    variant: 'error',
    description: T.PARKING_IS_UNAVAILABLE,
    title: T.OOPS,
  },
  [ErrorCode.TokenExpired]: {
    variant: 'warning',
    description: T.TOKEN_EXPIRED,
    title: T.OOPS,
  },
}
