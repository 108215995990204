import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, FlexBox, Input } from 'shared'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { CreditCard } from 'typedef/creditCards'
import { Translations, zipCodeRegex, blurLastInput } from 'utils'
import * as Yup from 'yup'

export interface ZipCodeFormProps {
  disabled?: boolean
  creditCard: CreditCard
  onSubmit: ({ zipCode }: { zipCode: string }) => void
}

export const ZipCodeForm: React.FC<ZipCodeFormProps> = props => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const zipCodeRef = React.useRef(null)
  const creditCardSchema = Yup.object().shape({
    billing: Yup.string()
      .required(t(Translations.ZIP_CODE_SHOULD_NOT_BE_EMPTY))
      .matches(zipCodeRegex, t(Translations.INVALID_ZIP_CODE)),
  })

  React.useEffect(() => {
    zipCodeRef.current?.focus()
  }, [])

  return (
    <Formik
      initialValues={{
        billing: props.creditCard?.zipCode || '',
      }}
      onSubmit={async ({ billing }) => {
        await props.onSubmit({ zipCode: billing })
      }}
      validateOnBlur
      validateOnChange={false}
      validationSchema={creditCardSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        validateForm,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  data-testid="billingInput"
                  name="billing"
                  autoComplete="shipping postal-code"
                  inputMode="text"
                  noMargin
                  refProp={zipCodeRef}
                  placeholder={t(Translations.ZIP_CODE)}
                  maxLength={15}
                  value={values.billing}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.billing}
                  onKeyDown={e => {
                    blurLastInput(e)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {props.children}
              </Grid>
              <Grid item xs={12}>
                <FlexBox>
                  <AppButton
                    variant="contained"
                    color="primary"
                    data-testid="billingCodeSubmitButton"
                    type="submit"
                    onClick={() => {
                      dispatch(parkCarSlice.actions.setZipCode(values.billing))
                      validateForm().then(errors => {
                        const entries = Object.entries(errors)
                        if (entries.length) {
                          entries.forEach(el => enqueueSnackbar(el[1]))
                        }
                      })
                    }}
                    disabled={
                      props.disabled ||
                      values.billing.length < 5 ||
                      isSubmitting
                    }
                  >
                    {t(Translations.PAY)}
                  </AppButton>
                </FlexBox>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ZipCodeForm
